<template>
  <div class="app-wrap">
    <h3>
      <i
        class="iconfont icon-a-fundmarkect-back"
        @click="$router.go(-1)"
      />
      安全中心
    </h3>
    <zh-step
      :index="index"
      :steps="['修改登录密码', '操作成功']"
    />
    <template v-if="index === 1">
      <div class="pw-t">
        <h3>修改登录密码</h3>
        <p>6~16位数字、字母及符号组合，区分大小写</p>
      </div>
      <div class="form-wrap">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          style="margin-top:62px;width:450px"
        >
          <el-form-item
            prop="oldPW"
            style="textAlign: center"
          >
            <el-input
              v-model="form.oldPW"
              :type="pw1 ? 'text' : 'password'"
              placeholder="输入原密码"
              class="pw-inpput"
            >
              <i
                slot="suffix"
                class="iconfont"
                :class="pw1 ? 'icon-home-eyes-open' : 'icon-home-eyes-close'"
                @click="pw1 = !pw1"
              />
            </el-input>
          </el-form-item>
          <el-form-item
            prop="passWord"
            style="textAlign: center"
          >
            <el-input
              v-model="form.passWord"
              :type="pw2 ? 'text' : 'password'"
              class="pw-inpput"
              placeholder="输入新密码"
            >
              <i
                slot="suffix"
                class="iconfont"
                :class="pw2 ? 'icon-home-eyes-open' : 'icon-home-eyes-close'"
                @click="pw2 = !pw2"
              />
            </el-input>
          </el-form-item>
          <el-form-item
            prop="againPassWord"
            style="textAlign: center"
          >
            <el-input
              v-model="form.againPassWord"
              :type="pw3 ? 'text' : 'password'"
              class="pw-inpput"
              placeholder="再次输入新密码"
            >
              <i
                slot="suffix"
                class="iconfont"
                :class="pw3 ? 'icon-home-eyes-open' : 'icon-home-eyes-close'"
                @click="pw3 = !pw3"
              />
            </el-input>
          </el-form-item>
          <el-form-item style="textAlign: center">
            <span
              class="sub-btn"
              @click="handleSubmit"
            >确认更改</span>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template v-if="index === 2">
      <div class="suc-w">
        <img src="@/assets/images/myFavors/susess.png">
        <p>登录密码修改成功！</p>
        <span
          class="suc-btn"
          @click="$router.go(-1)"
        >返回安全中心</span>
      </div>
    </template>
  </div>
</template>

<script>
import { loginPWCheck } from '@/utils/checkReg.js'
import {editPwd} from "@/api/bankmanagement"
  export default {
    data() {
      const validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入登录密码'));
        } else {
          if (this.form.againPassWord !== '') {
            this.$refs.form.validateField('againPassWord');
          }
          callback();
        }
      };
      const validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入登录密码'));
        } else if (value !== this.form.passWord) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        pw1: false,
        pw2: false,
        pw3: false,
        index: 1,
        form: {
          oldPW: '',
          passWord: '',
          againPassWord: ''
        },
        rules: {
          oldPW: [
            { required: true, message: '请输入登录密码', trigger: 'blur' },
            { pattern: loginPWCheck, message: '密码格式不正确' },
          ],
          passWord: [
            { validator: validatePass, trigger: 'blur' },
            { required: true, message: '请输入登录密码', trigger: 'blur' },
            { pattern: loginPWCheck, message: '密码格式不正确' },
          ],
          againPassWord: [
            { validator: validatePass2, trigger: 'blur' },
            { required: true, message: '请再次输入登录密码', trigger: 'blur' },
            { pattern: loginPWCheck, message: '密码格式不正确' },
          ],
        }
      }
    },
    methods: {
      handleSubmit() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            editPwd({
              password: this.form.passWord,
              oldPassword: this.form.oldPW,
              passwordCopy: this.form.againPassWord
            }).then(() => {
              this.index = 2
            })
          }
        })
        
      }
    },
  }
</script>

<style lang="less" scoped>
@import url('@/assets/css/zc-common.less');
.app-wrap > h3 {
  .flex(flex-start, center, row);
  font-size: 20px;
  color: #1F1F1F;
  padding-bottom: 30px;
  border-bottom: 1px solid #EDF1F7;
  font-weight: normal;
  i {
    font-size:18px;
    color: #BDC0CB;
    margin-right: 14px;
  }
}
.form-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
}
.pw-t {
  width: 100%;
  margin-top: 40px;
  &>h3 {
    font-weight: normal;
    font-size: 24px;
    color: #25293D;
    text-align: center;
  }
  &>p {
    margin-top: 22px;
    font-size: 12px;
    color: #BDC0CB;
    text-align: center;
  }
}

.sub-btn {
  .btn(450px, 50px);
  .inline-flex(center, center, row);
}

.suc-w {
  width: 100%;
  .flex(center, center, column);
  margin-top: 44px;
  &>img {
    width: 120px;
    height: 120px;
  }
  p {
    width: 100%;
    font-weight: bold;
    font-size: 18px;
    color: #25293D;
    text-align: center;
    margin-top: 54px;
  }
  .suc-btn {
    margin-top: 40px;
    .inline-flex(center, center, row);
    width: 350px;
    height: 50px;
    background: linear-gradient(109.88deg, #F7D9B7 -3.02%, #EABF96 98.27%);
    border-radius: 4px;
    font-size: 16px;
    color: #B88141;
    cursor: pointer;
  }
}
</style>
<style>
.pw-inpput {
  width:450px !important;
  height:50px !important
}
.pw-inpput .el-input__inner {
  height:50px !important;
  line-height: 50px !important;
}
.pw-inpput .el-input__suffix {
  right: 20px !important;
  display: flex;
  align-items: center;
}
</style>